<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6">
                        <div class="font-weight-bold body-1">
                            Editar Materia Prima
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-form ref="form" @submit.prevent="save">
                            <commodity-form ref="CommodityForm" :values="form" @save="save" :serverErrors="serverErrors" :stepIn="step" :stepClasses="stepClasses" @tabChanged="(val) => this.test(val)"></commodity-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button data-cy="cancel-btn" :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button data-cy="accept-btn" :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../../helpers/Utils';
import CommodityForm from "./Form.vue";
export default {
    components: {
        'commodity-form': CommodityForm,
    },
    watch: {
        ["step"]: function (v) {
            if(v == '1'){
                this.saveBtnData.text = "Siguiente";
                return;
            }
            this.saveBtnData.text = "Guardar";
        },
       
    },
	data(){
		return {
            id: this.$route.params.id,
            step: '1',
            stepClasses: {st1: '', st2: ''},
            loading: false,
            serverErrors: {},
            form: {
                supplies: [],
                name: "",
                description: "",
                brand: "",
                unit_id: "",
                section_unit: "",
                suppliers: [
                  
                ],
                variants: [
                    // {
                    //     size: "1",
                    //     color: "2",
                    //     price: "3"
                    // },
                    // {
                    //     size: "2",
                    //     color: "r",
                    //     price: "99"
                    // }
                ]
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "",
                to: "Commodities",
                block:false,
                click: () => {}
            },
            saveBtnData: {
                text: "Siguiente",
                icon: "",	
                click: this.nextStep,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
        test(val){
            // console.log(this.step);
        //    console.log(val);
            this.step = val+'';
            // console.log(this.step);
        },
       
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        index(){
            this.loading = true;
            this.$store.state.loading = true;
            this.$api.commodity.get(this.id)
                .then((resp) => {
                    // let permi = [];
                    this.form = {
                        ...this.form,
                        name: resp.data.name,
                        description: resp.data.description,
                        brand: resp.data.brand,
                        supplies: resp.data.supplies.split(","),
                        unit_id: resp.data.unit.id,
                        suppliers: resp.data.suppliers.map((item) => {return item.id}),
                        variants: resp.data.variants
                    }

                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },
        clearTabError(){
            this.stepClasses = {...this.stepClasses, st1: '', st2: ''};
        },

        nextStep(){
           
            if(this.step == '1'){
                if(this.validate()){
                    this.step = parseInt(this.step) + 1 +'';
                    this.clearTabError();
                    Utils.scrollToTop();
                    return;
                }
            }else if(this.step == '2'){
                if(this.validate()){
                    this.clearTabError();
                    this.save();
                    return;
                }
                
            }
            this.$store.dispatch('snackbarError', `Formulario incompleto`);
           
        },
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},

        /**
         * Set error class to tabs
         */
        setErrorClass(){
            this.clearTabError();
            let errors = Object.entries(this.$refs.form?.errorBag??{});
            //console.log(this.$refs.form.inputs, errors)
            let inputs = this.$refs.form?.inputs??{};
            //onsole.log(inputs[0].label);
            for (let index = 0; index < errors.length; index++) {
                if(errors[index][1]){
                    let found = inputs.find((inpt) => inpt._uid == errors[index][0])
                    //console.log(this.getParentName(found))
                    let parentName = this.getParentName(found);
                    if(parentName == 'p1')
                        this.stepClasses = {...this.stepClasses, st1: 'tab-error'};
                    if(parentName == 'p2')
                        this.stepClasses = {...this.stepClasses, st2: 'tab-error'};
                    
                }
            }
           
        },

        /**
         * Get name attrubute from html parent
         */
        getParentName(input){
            if("name" in input.$parent.$attrs)
                return input.$parent.$attrs.name;
            else
                return this.getParentName(input.$parent);
            
        },

        save(){
            
            console.log(this.form);
            if(this.validate()){
                
                let form = this.prepareRequest(this.form);
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.commodity.update(this.id, form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha actulizado correctamente`);
                        this.$router.push({name: 'Commodities'});
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("error" in error.response.data)){
                                    if(error.response.data.error instanceof Object){
                                        Object.keys(error.response.data.error).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.error;
                                            error.response.data.error[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.error)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)
                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                         this.setErrorClass();
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario incompleto`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));

            for (let i = 0; i < data.variants.length; i++) {
                delete data.variants[i].localId
                delete data.variants[i].actions
                delete data.variants[i].parameters
            }
            data.supplies = data.supplies.join(",")
            return data;
        }
    },
}
</script>

<style>

</style>